// R4075243

import {
  GptGenerateTextQueryVariables,
  GptTextToFeaturesQueryVariables,
} from 'src/gql/graphql';

import { graphql } from 'src/gql';
import { useMutation } from '@tanstack/react-query';
import { graphqlRequest } from './_request';

const gptTextToFeaturesDocument = graphql(/* GraphQL */ `
  query GptTextToFeatures($text: String!) {
    gptTextToFeatures(text: $text) {
      key
    }
  }
`);

const gptGenerateTextDocument = graphql(/* GraphQL */ `
  query GptGenerateText(
    $text: String!
    $type: String!
    $field: String!
    $length: String!
  ) {
    gptGenerateText(text: $text, type: $type, field: $field, length: $length)
  }
`);

export function gptTextToFeatures(variables: GptTextToFeaturesQueryVariables) {
  return graphqlRequest({
    document: gptTextToFeaturesDocument,
    variables,
  });
}

export function useGptTextToFeaturesMutation() {
  return useMutation({
    mutationFn: async (variables: GptTextToFeaturesQueryVariables) =>
      gptTextToFeatures(variables),
  });
}

export function useGptGenerateTextMutation() {
  return useMutation({
    mutationFn: async (variables: GptGenerateTextQueryVariables) =>
      graphqlRequest({
        document: gptGenerateTextDocument,
        variables,
      }),
  });
}
