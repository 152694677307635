import { useRef } from 'react';
import { DeepPartial } from 'react-hook-form';
import {
  Button,
  Card,
  Stack,
  Table,
  TableBody,
  TableContainer,
} from '@mui/material';
import { orderBy } from 'lodash';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';
import { TableHeadCustom, TableSkeleton, useTable } from 'src/components/table';
import { RouterLink } from 'src/routes/components';
import { useBoolean } from 'src/hooks/use-boolean';
import { FeatureModel } from 'src/gql/graphql';
import { useGetFeaturesQuery } from 'src/services/features';
import FeatureTableRow from '../feature-table-row';
import FeatureNewEditDrawer from '../feature-new-edit-drawer';

export function FeatureListView() {
  const addFeature = useBoolean();
  const featureRef = useRef<DeepPartial<FeatureModel> | null>(null);

  const table = useTable({
    defaultOrderBy: 'key',
  });
  const query = useGetFeaturesQuery();

  const items = orderBy(
    query.data?.items ?? [],
    [table.orderBy],
    [table.order]
  );

  const denseHeight = table.dense ? 60 : 80;
  const notFound = query.isFetched && !items.length;

  const handleAdd = () => {
    featureRef.current = null;
    addFeature.onTrue();
  };

  const handleEdit = (item: DeepPartial<FeatureModel>) => () => {
    featureRef.current = item;
    addFeature.onTrue();
  };

  return (
    <>
      <Stack>
        <CustomBreadcrumbs
          heading="Features"
          links={[]}
          action={
            <Stack gap={2} direction="row">
              <Button
                variant="contained"
                startIcon={<Iconify icon="mingcute:add-line" />}
                onClick={handleAdd}
              >
                New features
              </Button>
            </Stack>
          }
          sx={{ mb: { xs: 3, md: 5 } }}
        />

        <Card>
          <TableContainer sx={{ overflow: 'unset' }}>
            <Scrollbar>
              <Table
                size={table.dense ? 'small' : 'medium'}
                sx={{ minWidth: 960 }}
              >
                <TableHeadCustom
                  order={table.order}
                  orderBy={table.orderBy}
                  headLabel={[
                    { id: 'key', label: 'Key' },
                    { id: 'name', label: 'Name' },
                    { id: 'nameResale', label: 'Name Resale' },
                    { id: 'nameAI', label: 'Name AI' },
                    { id: 'propertyType', label: 'Property Type' },
                    { id: 'updatedAt', label: 'Updated At' },
                    { id: 'createdAt', label: 'Created At' },
                  ]}
                  onSort={table.onSort}
                  sx={{ position: 'sticky', top: 0, zIndex: 99 }}
                />

                <TableBody>
                  {query.isLoading &&
                    [...Array(table.rowsPerPage)].map((i, index) => (
                      <TableSkeleton key={index} sx={{ height: denseHeight }} />
                    ))}

                  {items.map((item, index) => (
                    <FeatureTableRow
                      key={index}
                      item={item}
                      onClick={handleEdit(item)}
                    />
                  ))}
                </TableBody>
              </Table>
            </Scrollbar>
          </TableContainer>
        </Card>
      </Stack>

      <FeatureNewEditDrawer
        open={addFeature.value}
        onClose={addFeature.onFalse}
        featureRef={featureRef}
      />
    </>
  );
}
