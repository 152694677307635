import { useMutation, useQuery } from '@tanstack/react-query';
import { UpsertFeatureMutationVariables } from 'src/gql/graphql';
import { graphql } from 'src/gql';
import { graphqlRequest, queryClient } from './_request';

const getFeaturesDocument = graphql(/* GraphQL */ `
  query GetFeatures {
    items: getFeatures {
      key
      group
      name
      icon
      nameResale
      nameAI
      propertyType
      createdAt
      updatedAt
      parent {
        key
      }
    }
  }
`);

const updateFeatureDocument = graphql(/* GraphQL */ `
  mutation UpsertFeature($data: FeatureUpsertInput!) {
    upsertFeature(data: $data)
  }
`);

export function useGetFeaturesQuery() {
  return useQuery({
    queryKey: ['features'],
    staleTime: Infinity,
    queryFn: async () => graphqlRequest({ document: getFeaturesDocument }),
  });
}

export function useUpsertFeatureMutation() {
  return useMutation({
    mutationFn: async (variables: UpsertFeatureMutationVariables) =>
      graphqlRequest({ document: updateFeatureDocument, variables }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['features'] });
    },
  });
}
