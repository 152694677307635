import { map } from 'lodash';
import { useMutation } from '@tanstack/react-query';
import { restRequest } from './_request';

export function s3Upload({ key, file }: { key: string; file: File | Blob }) {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('key', key);

  return restRequest<{ url: string }>({
    url: 's3/upload',
    method: 'POST',
    body: formData,
  });
}

/**
 * Upload multiple files to S3 and return the URLs
 * @param key S3 key prefix
 * @param data Object with file URLs as keys and File/Blob as values
 * @returns Object with file URLs as keys and S3 URLs as values
 * @example s3ObjectUpload('properties/1', { 'blob:file1.jpg': file1, 'blob:file2.jpg': file2 })
 */
export async function s3MultipleUpload(
  key: string,
  data: Record<string, File | Blob>
): Promise<Record<string, string>> {
  const response: Record<string, string> = {};

  await Promise.all(
    map(data, async (file, url) => {
      // Upload to S3
      const uploaded = await s3Upload({
        key: `${key}/${file.name}`,
        file,
      });

      response[url] = uploaded.url;
    })
  );

  return response;
}

export function useS3UploadMutation() {
  return useMutation({
    mutationFn: async (variables: { key: string; file: File | Blob }) =>
      s3Upload(variables),
  });
}
