import { Container } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { useSettingsContext } from 'src/components/settings';
import { FeatureListView } from 'src/modules/features/views/feature-list-view';

export default function FeatureListPage() {
  const settings = useSettingsContext();

  return (
    <>
      <Helmet>
        <title>Features</title>
      </Helmet>

      <Container maxWidth={settings.themeStretch ? false : 'xl'}>
        <FeatureListView />
      </Container>
    </>
  );
}
