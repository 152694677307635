import { Stack, TableCell, TableRow, Typography } from '@mui/material';
import { DeepPartial } from 'react-hook-form';
import { FeatureModel } from 'src/gql/graphql';
import { fDate } from 'src/utils/format-time';

type FeatureTableRowProps = {
  item: DeepPartial<FeatureModel>;
  onClick?: VoidFunction;
};

export default function FeatureTableRow({
  item,
  onClick,
}: FeatureTableRowProps) {
  return (
    <TableRow
      hover
      sx={{ cursor: 'pointer', position: 'relative', clipPath: 'inset(0)' }}
    >
      <TableCell>
        <Typography noWrap variant="inherit">
          {item.parent?.key ? `${item.parent.key} / ${item.key}` : item.key}
        </Typography>
        <Stack sx={{ position: 'absolute', inset: 0 }} onClick={onClick} />
      </TableCell>
      <TableCell>
        <Typography noWrap variant="inherit">
          {item.name}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography noWrap variant="inherit">
          {item.nameResale}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography noWrap variant="inherit">
          {item.nameAI}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography noWrap variant="inherit">
          {item.propertyType?.join(', ')}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography noWrap variant="inherit">
          {fDate(item.updatedAt)}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography noWrap variant="inherit">
          {fDate(item.createdAt)}
        </Typography>
      </TableCell>
    </TableRow>
  );
}
