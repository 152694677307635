import { useController, useFormContext } from 'react-hook-form';
import { LoadingButton } from '@mui/lab';
import { FormGroup, Tooltip } from '@mui/material';
import { PropertyType } from 'src/gql/graphql';
import { useGptGenerateTextMutation } from 'src/services/gpt';
import RHFTextField, { RHFTextFieldProps } from './rhf-text-field';
import Iconify from '../iconify';
import { useSnackbar } from '../snackbar';

type Props = RHFTextFieldProps & {
  fromName: string;
  propertyType: PropertyType;
  fieldType: 'name' | 'content';
  gptLength?: string;
};

export default function RHFGptTextField({
  fromName,
  propertyType,
  fieldType,
  gptLength,
  ...rest
}: Props) {
  const { getValues } = useFormContext();
  const { enqueueSnackbar } = useSnackbar();

  const { field } = useController({ name: rest.name });
  const { mutateAsync: generateText, isPending } = useGptGenerateTextMutation();

  const handleGenerateText = async () => {
    const text = String(getValues(fromName)).trim();

    if (!text) {
      enqueueSnackbar({
        message: 'Please enter a context',
        variant: 'error',
      });
      return;
    }

    const res = await generateText({
      text,
      type: propertyType,
      field: fieldType,
      length: gptLength || '1500',
    });

    field.onChange(res.gptGenerateText);
  };

  return (
    <FormGroup row sx={{ gap: 3, width: rest.fullWidth ? '100%' : undefined }}>
      <RHFTextField {...rest} fullWidth={false} sx={{ flexGrow: 1 }} />

      <Tooltip title="Generate context by GPT">
        <LoadingButton
          variant="outlined"
          loading={isPending}
          onClick={handleGenerateText}
        >
          <Iconify icon="mdi:magic" />
        </LoadingButton>
      </Tooltip>
    </FormGroup>
  );
}
