import { last } from 'lodash';
import { useLocation } from 'react-router';
import { useRouter } from 'src/routes/hooks';

// @mui
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { Button } from '@mui/material';
//
import { CustomBreadcrumbsProps } from './types';
import LinkItem from './link-item';
import Iconify from '../iconify';

// ----------------------------------------------------------------------

export default function CustomBreadcrumbs({
  links,
  action,
  heading,
  headingLink,
  moreLink,
  activeLast,
  canBack,
  backLink,
  sx,
  ...other
}: CustomBreadcrumbsProps) {
  const router = useRouter();
  const location = useLocation();
  const lastLink = last(links)?.name;

  const isCanBack = canBack && location?.state?.from === 'can-back';

  // Back to previous page
  const handleBack = () => {
    if (isCanBack) {
      router.back();
    } else if (backLink) {
      router.push(backLink);
    }
  };

  return (
    <Stack gap={1} sx={{ ...sx }}>
      {(isCanBack || backLink) && (
        <Box>
          <Button
            onClick={handleBack}
            startIcon={<Iconify icon="eva:arrow-ios-back-fill" />}
            size="small"
          >
            Back
          </Button>
        </Box>
      )}

      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        gap={8}
      >
        {/* Heading */}
        {!!heading &&
          (headingLink ? (
            <Link
              href={headingLink}
              target="_blank"
              sx={{
                textDecoration: 'none',
                '&:hover': {
                  textDecoration: 'none',
                },
              }}
            >
              <Typography
                variant="h4"
                color="black"
                gutterBottom={!!links.length}
              >
                {heading}
              </Typography>
            </Link>
          ) : (
            <Typography variant="h4" gutterBottom={!!links.length}>
              {heading}
            </Typography>
          ))}

        {action && <Box sx={{ flexShrink: 0 }}> {action} </Box>}
      </Stack>

      {/* BREADCRUMBS */}
      {!!links.length && (
        <Breadcrumbs separator={<Separator />} {...other}>
          {links.map((link, index) => (
            <LinkItem
              key={index}
              link={link}
              activeLast={activeLast}
              disabled={link.name === lastLink}
            />
          ))}
        </Breadcrumbs>
      )}

      {/* MORE LINK */}
      {!!moreLink && (
        <Box sx={{ mt: 2 }}>
          {moreLink.map((href) => (
            <Link
              key={href}
              href={href}
              variant="body2"
              target="_blank"
              rel="noopener"
              sx={{ display: 'table' }}
            >
              {href}
            </Link>
          ))}
        </Box>
      )}
    </Stack>
  );
}

// ----------------------------------------------------------------------

function Separator() {
  return (
    <Box
      component="span"
      sx={{
        width: 4,
        height: 4,
        borderRadius: '50%',
        bgcolor: 'text.disabled',
      }}
    />
  );
}
