import { Controller, useFormContext } from 'react-hook-form';
import { DatePicker, DatePickerProps } from '@mui/x-date-pickers';
import { FormControl } from '@mui/material';

type RHFDatePickerProps = DatePickerProps<any> & {
  name: string;
};

export default function RHFDatePicker({ name, ...other }: RHFDatePickerProps) {
  const { control } = useFormContext();

  return (
    <FormControl fullWidth>
      <Controller
        control={control}
        name={name}
        render={({ field }) => (
          <DatePicker {...field} format="DD/MM/YYYY" {...other} />
        )}
      />
    </FormControl>
  );
}
