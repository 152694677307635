import { LoadingButton } from '@mui/lab';
import {
  Box,
  Divider,
  Drawer,
  MenuItem,
  Stack,
  Typography,
} from '@mui/material';
import { filter } from 'lodash';
import { useEffect, useMemo } from 'react';
import { DeepPartial, FormProvider, useForm } from 'react-hook-form';
import { RHFSelect, RHFTextField } from 'src/components/hook-form';
import Scrollbar from 'src/components/scrollbar';
import { FeatureModel } from 'src/gql/graphql';
import {
  useGetFeaturesQuery,
  useUpsertFeatureMutation,
} from 'src/services/features';

type Props = {
  open: boolean;
  onClose: VoidFunction;
  featureRef: React.RefObject<DeepPartial<FeatureModel> | null>;
};

type FormValues = DeepPartial<FeatureModel & { parentKey: string }>;

const defaultValues: FormValues = {
  parentKey: '',
  key: '',
  name: '',
  nameAI: '',
  nameResale: '',
};

export default function FeatureNewEditDrawer({
  open,
  onClose,
  featureRef,
}: Props) {
  const title = featureRef.current?.key
    ? `Edit Feature #${featureRef.current.key}`
    : 'New Feature';
  const mutate = useUpsertFeatureMutation();
  const { data: features } = useGetFeaturesQuery();

  const rootFeatures = useMemo(
    () => filter(features?.items, (item) => !item.parent?.key),
    [features]
  );

  const form = useForm<FormValues>({
    defaultValues,
  });

  const { reset, formState } = form;

  // Reset form when open or close
  useEffect(() => {
    if (open) {
      reset(
        {
          parentKey: featureRef.current?.parent?.key ?? '',
          key: featureRef.current?.key ?? '',
          name: featureRef.current?.name ?? '',
          nameResale: featureRef.current?.nameResale ?? '',
          nameAI: featureRef.current?.nameAI ?? '',
        },
        { keepDirty: false, keepTouched: false }
      );
    } else {
      reset(defaultValues);
    }
  }, [open, reset, featureRef]);

  const handleSubmit = form.handleSubmit(async (values) => {
    // Save data
    await mutate.mutate({
      data: {
        key: values.key,
        parentKey: values.parentKey || null,
        name: values.name || '',
        nameResale: values.nameResale || null,
        nameAI: values.nameAI || null,
      },
    });

    // If edit, reset form with current values else close drawer
    if (featureRef.current?.key) {
      form.reset(values);
    } else {
      onClose();
    }
  });

  return (
    <FormProvider {...form}>
      <Drawer
        open={open}
        onClose={onClose}
        anchor="right"
        slotProps={{
          backdrop: { invisible: true },
        }}
        PaperProps={{
          sx: { width: 560 },
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ p: 2.5 }}
        >
          <Typography variant="h6">{title}</Typography>
        </Stack>

        <Divider />

        <Scrollbar
          sx={{
            height: 1,
            '& .simplebar-content': {
              height: 1,
              display: 'flex',
              flexDirection: 'column',
            },
          }}
        >
          <Stack
            spacing={3}
            sx={{
              pt: 3,
              pb: 5,
              px: 2.5,
            }}
          >
            <RHFSelect name="parentKey" label="Parent Key">
              <MenuItem value="">None</MenuItem>

              {rootFeatures.map((item) => (
                <MenuItem key={item.key} value={item.key}>
                  {item.name}
                </MenuItem>
              ))}
            </RHFSelect>

            <RHFTextField
              name="key"
              label="Key"
              disabled={!!featureRef.current?.key}
            />
            <RHFTextField name="name" label="Name" />
            <RHFTextField name="nameResale" label="Name Resale" />
            <RHFTextField name="nameAI" label="Name AI" />
          </Stack>
        </Scrollbar>

        <Divider />

        <Box sx={{ p: 2.5 }}>
          <LoadingButton
            fullWidth
            variant="contained"
            size="large"
            disabled={!formState.isDirty}
            loading={mutate.isPending || formState.isSubmitting}
            onClick={handleSubmit}
          >
            Save
          </LoadingButton>
        </Box>
      </Drawer>
    </FormProvider>
  );
}
