import { truncate } from 'lodash';

// @mui
import { Tooltip, Link, Box } from '@mui/material';
// routes
import { RouterLink } from 'src/routes/components';
//
import { BreadcrumbsLinkProps } from './types';

// ----------------------------------------------------------------------

type Props = {
  link: BreadcrumbsLinkProps;
  activeLast?: boolean;
  disabled: boolean;
};

export default function BreadcrumbsLink({ link, activeLast, disabled }: Props) {
  const { name, href, icon } = link;

  const styles = {
    typography: 'body2',
    alignItems: 'center',
    color: 'text.primary',
    display: 'inline-flex',
    ...(disabled &&
      !activeLast && {
        cursor: 'default',
        // pointerEvents: 'none',
        color: 'text.disabled',
      }),
  };

  const content = truncate(name, { length: 28 });

  const renderContent = (
    <Tooltip title={content !== name ? name : undefined}>
      <Box component="span">
        {icon && (
          <Box
            component="span"
            sx={{
              mr: 1,
              display: 'inherit',
              '& svg': { width: 20, height: 20 },
            }}
          >
            {icon}
          </Box>
        )}

        {content}
      </Box>
    </Tooltip>
  );

  if (href) {
    return (
      <Link component={RouterLink} href={href} sx={styles}>
        {renderContent}
      </Link>
    );
  }

  return <Box sx={styles}> {renderContent} </Box>;
}
