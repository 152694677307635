import { Container } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router';
import { useSettingsContext } from 'src/components/settings';
import PropertyAssetsView from 'src/modules/properties/views/property-assets-view';
import { useGetPropertyQuery } from 'src/services/properties';

export default function PropertyAssetsPage() {
  const settings = useSettingsContext();
  const { id } = useParams();
  const query = useGetPropertyQuery({ id: id! });
  const property = query.data?.property;

  const title = query.isFetched
    ? `Assets: ${property?.type?.name} - ${property?.name}`
    : `Property #${id}`;

  if (!property) {
    return null;
  }

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>

      <Container maxWidth={settings.themeStretch ? false : 'xl'}>
        <PropertyAssetsView property={property} />
      </Container>
    </>
  );
}
